import { useCleanPathname } from '@/hooks/use-clean-pathname';
import { Nullable } from '@/types';
import { useSearchParams } from 'next/navigation';
import urlJoin from 'proper-url-join';
import { useCallback } from 'react';

type PathnameWithQueryInput = {
  pathname?: Nullable<string>;
  query?: Nullable<Record<string, any>>;
};

export function usePathnameWithQuery(input?: PathnameWithQueryInput) {
  const pathname = useCleanPathname();
  const searchParams = useSearchParams();
  const searchParamsObj = Object.fromEntries(searchParams.entries());

  const pathnameWithQuery = urlJoin(input?.pathname || pathname, {
    query: {
      ...searchParamsObj,
      ...(input?.query ?? {})
    }
  });

  const addToQuery = useCallback(
    (callbackInput?: PathnameWithQueryInput) => {
      return urlJoin(callbackInput?.pathname || pathnameWithQuery, {
        query: callbackInput?.query ?? {}
      });
    },
    [pathnameWithQuery]
  );

  return {
    pathnameWithQuery,
    addToQuery
  };
}
