'use client';

import outputs from '@/__generated__/amplify_outputs.json';
import facebookLogo from '@/assets/logos/facebook.webp';
import googleLogo from '@/assets/logos/google.webp';
import { snackbarStore } from '@/stores/snackbar-store';
import { AnalyticsProps } from '@/types';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { signIn } from 'next-auth/react';
import Image from 'next/image';

export type SocialSignupButtonsProps = StackProps & AnalyticsProps;

const identityProviders = outputs.auth.oauth.identity_providers;

const isGoogleAuthEnabled = identityProviders.includes('GOOGLE');
const isFacebookAuthEnabled = identityProviders.includes('FACEBOOK');

export function SocialSignup({
  analyticsParentId,
  analyticsContext,
  ...stackProps
}: SocialSignupButtonsProps) {
  const { i18n } = useLingui();
  const handleSignUpWithGoogle = async () => {
    try {
      snackbarStore.set.close();
      rudderanalytics.track('Click', {
        element_id: 'google-sign-up-button',
        type: 'BUTTON',
        parent_id: analyticsParentId,
        context: analyticsContext
      });
      await signIn('google');
    } catch (e) {
      console.log('error: ', e);
      snackbarStore.set.create('error', i18n.t('error.something-went-wrong'));
    }
  };

  const handleSignUpWithFacebook = async () => {
    try {
      snackbarStore.set.close();
      rudderanalytics.track('Click', {
        element_id: 'facebook-sign-up-button',
        type: 'BUTTON',
        parent_id: analyticsParentId,
        context: analyticsContext
      });
      await signIn('facebook');
    } catch (e) {
      console.log('error: ', e);
      snackbarStore.set.create('error', i18n.t('error.something-went-wrong'));
    }
  };

  if (!isGoogleAuthEnabled && !isFacebookAuthEnabled) {
    return null;
  }

  return (
    <Stack direction="column" spacing={{ xs: 1.5, sm: 2 }} {...stackProps}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        gap={{ xs: 1.5, sm: 2 }}
      >
        {isGoogleAuthEnabled && (
          <Button
            data-testid="google-sign-up-button"
            id="google-sign-up-button"
            variant="outlined"
            size="large"
            endIcon={
              <Image
                width={20}
                height={20}
                src={googleLogo}
                alt={i18n.t('sign-up.google-logo')}
              />
            }
            sx={{
              flexGrow: 1,
              flexShrink: 0,
              borderColor: 'action.disabled',
              color: 'text.primary',
              fontSize: {
                xs: 'body2.fontSize',
                sm: 'button.fontSize'
              }
            }}
            onClick={handleSignUpWithGoogle}
            aria-label={i18n.t('sign-up.google-sign-up-button')}
          >
            <Trans id="sign-up.google-sign-up-button">Continue With</Trans>
          </Button>
        )}

        {isFacebookAuthEnabled && (
          <Button
            data-testid="facebook-sign-up-button"
            id="facebook-sign-up-button"
            variant="outlined"
            size="large"
            endIcon={
              <Image
                width={20}
                height={20}
                src={facebookLogo}
                alt={i18n.t('sign-up.facebook-logo')}
              />
            }
            sx={{
              flexGrow: 1,
              flexShrink: 0,
              borderColor: 'action.disabled',
              color: 'text.primary',
              fontSize: {
                xs: 'body2.fontSize',
                sm: 'button.fontSize'
              }
            }}
            onClick={handleSignUpWithFacebook}
            aria-label={i18n.t('sign-up.facebook-sign-up-button')}
          >
            <Trans id="sign-up.facebook-sign-up-button">Continue With</Trans>
          </Button>
        )}
      </Box>
      <Stack direction="row" spacing={1} my={2}>
        <Box py={1.25} flex={1}>
          <Divider />
        </Box>
        <Typography color="text.secondary" px={1} variant="body2">
          <Trans id="sign-up.or">Or</Trans>
        </Typography>
        <Box py={1.25} flex={1}>
          <Divider />
        </Box>
      </Stack>
    </Stack>
  );
}
